.torrents {
  display: grid;
  grid-template-columns: 50px repeat(8, auto);
}

.torrents-header {
  background-color: #353637;
  text-align: left;
  white-space: nowrap;
  font-weight: 900;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  padding: 0.75rem 0;
  padding-right: 8px;
}

.torrents-data {
  white-space: nowrap;
  border-bottom: 1px solid #555555;
  padding: 0.25rem 0;
  padding-right: 8px;
}

.torrents-data a {
  text-decoration: none;
  color: inherit;
}

.torrents-data a:hover {
  text-decoration: underline;
}

.delete-button {
  display: flex;
  justify-content: center;
  padding-right: 0;
}

.confirm-button {
  color: #f9a230;
}
