@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap);
form {
  width: 90%;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  border: 1px solid #e2e2e2;
  margin: 20px auto;
  padding: 20px;
}

form h1 {
  margin: 0;
  text-align: center;
}

form label {
  margin-bottom: 6px;
  font-size: 1.1rem;
}

form input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #c3c3c3;
  background-color: #414141;
  color: #e2e2e2;
  outline: none;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

form button {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  border: 1px solid white;
  background: #363636;
  color: #e2e2e2;
  width: 150px;
  cursor: pointer;
  font-weight: bold;
}

form button:hover {
  background: #e2e2e2;
  color: black;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alertify .ajs-dialog {
  background-color: #181a1b;
}

.alertify .ajs-body,
.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  color: #e2e2e2;
}

.alertify .ajs-footer,
.alertify .ajs-header {
  background-color: #1b1d1e;
  color: #e2e2e2;
}

.button-icon {
  padding: 0;
  border-radius: 0.5rem;
  font-weight: bold;
  border: none;
  background: transparent;
  color: inherit;
  font-size: 1.5rem;
  font-family: inherit;
  cursor: pointer;
}

.button-icon:hover,
.button-icon:active {
  color: #707070;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #212121;
  color: #e2e2e2;
  font-family: "Roboto", sans-serif;
}

.container {
  display: grid;
  grid-template-rows: 3.4rem auto;
  height: 100vh;
}

.info {
  width: 90%;
  margin: auto;
  max-width: 40rem;
  padding: 1rem;
  font-size: 2rem;
  border: 1px solid white;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
}

.wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.torrents {
  display: grid;
  grid-template-columns: 50px repeat(8, auto);
}

.torrents-header {
  background-color: #353637;
  text-align: left;
  white-space: nowrap;
  font-weight: 900;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  padding: 0.75rem 0;
  padding-right: 8px;
}

.torrents-data {
  white-space: nowrap;
  border-bottom: 1px solid #555555;
  padding: 0.25rem 0;
  padding-right: 8px;
}

.torrents-data a {
  text-decoration: none;
  color: inherit;
}

.torrents-data a:hover {
  text-decoration: underline;
}

.delete-button {
  display: flex;
  justify-content: center;
  padding-right: 0;
}

.confirm-button {
  color: #f9a230;
}

.loading {
  width: 90%;
  margin: auto;
  max-width: 40rem;
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3c4043;
  width: 100vw;
  position: fixed;
}

.navbar-brand {
  font-size: 2rem;
  font-weight: 900;
  text-decoration: none;
  color: inherit;
  margin: 0.5rem 1rem;
}

.navbar-name {
  font-size: 1.3rem;
  font-weight: normal;
  margin-right: auto;
}

.navbar-brand:hover {
  text-decoration: underline;
}

.navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-item {
  margin: 0 0.5rem;
  font-size: 1.5rem;
}

.nav-link {
  text-decoration: none;
  color: inherit;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
}

.nav-button {
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  border: none;
  background: transparent;
  color: inherit;
  font-size: 1.5rem;
  font-family: inherit;
  cursor: pointer;
}

.nav-input {
  padding: 5px;
  border: none;
  background-color: #e2e2e2;
  color: #212121;
  outline: none;
  font-size: 1.1rem;
}

.nav-link:hover,
.nav-link:active,
.nav-button:hover,
.nav-button:active {
  background-color: #34383a;
  color: #b4b5b6;
}

.data-container {
  display: flex;
}

.data-container > div {
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  border: 1px solid #e2e2e2;
  margin: 20px auto;
  padding: 20px;
  font-size: 1.25rem;
  align-items: flex-start;
}

.data-folder {
  color: #6ba4e7;
  text-decoration: none;
}

.data-folder:hover {
  text-decoration: underline;
}

.data-file {
  text-decoration: none;
  color: inherit;
}

.data-file:hover {
  text-decoration: underline;
  cursor: pointer;
}

.data-separator {
  margin: 0.5rem 0;
}

.data-info {
  margin: auto;
  font-size: 2rem;
  font-weight: bold;
}

