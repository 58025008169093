.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3c4043;
  width: 100vw;
  position: fixed;
}

.navbar-brand {
  font-size: 2rem;
  font-weight: 900;
  text-decoration: none;
  color: inherit;
  margin: 0.5rem 1rem;
}

.navbar-name {
  font-size: 1.3rem;
  font-weight: normal;
  margin-right: auto;
}

.navbar-brand:hover {
  text-decoration: underline;
}

.navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-item {
  margin: 0 0.5rem;
  font-size: 1.5rem;
}

.nav-link {
  text-decoration: none;
  color: inherit;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
}

.nav-button {
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  border: none;
  background: transparent;
  color: inherit;
  font-size: 1.5rem;
  font-family: inherit;
  cursor: pointer;
}

.nav-input {
  padding: 5px;
  border: none;
  background-color: #e2e2e2;
  color: #212121;
  outline: none;
  font-size: 1.1rem;
}

.nav-link:hover,
.nav-link:active,
.nav-button:hover,
.nav-button:active {
  background-color: #34383a;
  color: #b4b5b6;
}
