.loading {
  width: 90%;
  margin: auto;
  max-width: 40rem;
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}
