@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");

@import "../node_modules/alertifyjs/build/css/alertify.min.css";
@import "../node_modules/alertifyjs/build/css/themes/default.min.css";

@import "./styles/Form.css";
@import "./styles/Spinner.css";
@import "./styles/Alertify.css";
@import "./styles/Button.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #212121;
  color: #e2e2e2;
  font-family: "Roboto", sans-serif;
}

.container {
  display: grid;
  grid-template-rows: 3.4rem auto;
  height: 100vh;
}

.info {
  width: 90%;
  margin: auto;
  max-width: 40rem;
  padding: 1rem;
  font-size: 2rem;
  border: 1px solid white;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
}

.wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
