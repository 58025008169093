.data-container {
  display: flex;
}

.data-container > div {
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  border: 1px solid #e2e2e2;
  margin: 20px auto;
  padding: 20px;
  font-size: 1.25rem;
  align-items: flex-start;
}

.data-folder {
  color: #6ba4e7;
  text-decoration: none;
}

.data-folder:hover {
  text-decoration: underline;
}

.data-file {
  text-decoration: none;
  color: inherit;
}

.data-file:hover {
  text-decoration: underline;
  cursor: pointer;
}

.data-separator {
  margin: 0.5rem 0;
}

.data-info {
  margin: auto;
  font-size: 2rem;
  font-weight: bold;
}
