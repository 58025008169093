.button-icon {
  padding: 0;
  border-radius: 0.5rem;
  font-weight: bold;
  border: none;
  background: transparent;
  color: inherit;
  font-size: 1.5rem;
  font-family: inherit;
  cursor: pointer;
}

.button-icon:hover,
.button-icon:active {
  color: #707070;
}
