form {
  width: 90%;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  border: 1px solid #e2e2e2;
  margin: 20px auto;
  padding: 20px;
}

form h1 {
  margin: 0;
  text-align: center;
}

form label {
  margin-bottom: 6px;
  font-size: 1.1rem;
}

form input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #c3c3c3;
  background-color: #414141;
  color: #e2e2e2;
  outline: none;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

form button {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  border: 1px solid white;
  background: #363636;
  color: #e2e2e2;
  width: 150px;
  cursor: pointer;
  font-weight: bold;
}

form button:hover {
  background: #e2e2e2;
  color: black;
}
