.alertify .ajs-dialog {
  background-color: #181a1b;
}

.alertify .ajs-body,
.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  color: #e2e2e2;
}

.alertify .ajs-footer,
.alertify .ajs-header {
  background-color: #1b1d1e;
  color: #e2e2e2;
}
